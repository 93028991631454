<template>
  <v-container fluid class="service-detail-height overflow-auto">
    <v-row>
      <v-col cols="6" class="pb-0 pl-4">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="position: relative"
        >
          <table class="width-100">
            <tr>
              <th colspan="2" class="font-size-18 py-2">Basic Information</th>
            </tr>
            <!--       <pre>{{ detail }}</pre> -->
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Name
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.name">{{ detail.name }}</span>
                <em v-else class="text--secondary"> no name </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Part ID
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.barcode">{{
                  detail.barcode
                }}</span>
                <em v-else class="text--secondary"> no part id </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                GDS Code
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.gds_code">{{
                  detail.gds_code
                }}</span>
                <em v-else class="text--secondary"> no gds code </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2 d-flex" width="250">
                Description
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.description">{{
                  detail.description
                }}</span>
                <em v-else class="text--secondary"> no description </em>
              </td>
            </tr>

            <tr>
              <template v-if="detail.unit">
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Unit
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span v-if="detail && detail.unit"> {{ detail.unit }}</span>
                  <em v-else class="text--secondary"> no unit</em>
                </td>
              </template>
            </tr>

            <tr>
              <template v-if="detail.initial_stock">
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  PA Size/ Cut Quantity
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span v-if="detail && detail.initial_stock">
                    {{ detail.initial_stock }}</span
                  >
                  <em v-else class="text--secondary">
                    no pa size/ cut quantity</em
                  >
                </td>
              </template>
            </tr>

            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Length
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.length"> {{ detail.length }} {{ detail.length_unit != 0 ? detail.length_unit: '' }}</span >
                <em v-else class="text--secondary"> no length</em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Weight
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.weight"> {{ detail.weight }} {{ detail.weight_unit != 0 ? detail.weight_unit:'' }}</span >
                <em v-else class="text--secondary"> no weight</em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Height
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.height"> {{ detail.height }} {{ detail.height_unit != 0 ? detail.height_unit:'' }}</span >
                <em v-else class="text--secondary"> no height</em>
              </td>
            </tr>

            <tr class="custom-border-top">
              <th colspan="2" class="font-size-18 py-2">Cost Information</th>
            </tr>

            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Unit Cost
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ $accountingJS.formatMoney(detail.selling_cost) }}</template
                >
              </td>
            </tr>

            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Cost FC
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{
                    $accountingJS.formatMoney(detail.purchase_cost)
                  }}</template
                >
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
      <v-col cols="6" class="pb-0 gray-background pl-4">
        <perfect-scrollbar
          ref="sidebarScrollbar"
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table v-if="false" class="width-100">
            <tr>
              <th colspan="2" class="font-size-18 py-2">
                Warranty Information
              </th>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Duration
              </td>
              <td
                class="font-weight-600 font-size-18 py-2"
                v-if="lodash.isEmpty(detail.warranty_data) === false"
              >
                <template v-for="(warranty, index) in detail.warranty_data">
                  <span
                    :key="'warranty-data-' + index"
                    class="text--uppercase pr-2"
                    >{{ warranty.value }} {{ warranty.field
                    }}<template v-if="warranty.value != 1">s</template></span
                  >
                </template>
              </td>
              <em v-else class="text-muted"> no warranty</em>
            </tr>
            <tr
              v-if="false"
              :class="{
                'custom-border-top':
                  lodash.isEmpty(detail.warranty_data) === false,
              }"
            >
              <th colspan="2" class="font-size-18 py-2">Accounting Stock</th>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Re-Order Level</span
                    >
                  </template>
                  <span
                    >When the stock reaches the reorder level, <br />a
                    notification will be sent to you.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.reorder_level)
                }}</template>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Initial Quantity</span
                    >
                  </template>
                  <span
                    >The quantity available for sale at<br />the beginning of
                    the accounting period.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.initial_stock)
                }}</template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Stock on Hand</span
                    >
                  </template>
                  <span>Current stock available for this item.</span>
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.stock_on_hand)
                }}</template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Committed Stock</span
                    >
                  </template>
                  <span
                    >Stock that is committed to job(s) but not yet
                    invoiced.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.committed_stock)
                }}</template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Available for Sale</span
                    >
                  </template>
                  <span
                    >Available for sale = Stock on Hand - Committed Stock.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.available_stock)
                }}</template>
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  data() {
    return {
      product: 0,
      dataLoading: true,
      summary: "this_week",
      summaryFilter: [
        { text: "This Week", value: "this_week" },
        { text: "Previous Week", value: "previous_week" },
        { text: "This Month", value: "this_month" },
        { text: "Previous Month", value: "previous_month" },
        /*{ text: "This Quarter", value: "this_quarter" },
        { text: "Previous Quarter", value: "previous_quarter" },*/
        { text: "This Year", value: "this_year" },
        { text: "Previous Year", value: "previous_year" },
      ],
      chartOptions: {
        chart: {
          id: "product-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Name",
          key: "name",
          child: false,
        },
        /*{
          title: "Reference",
          key: "reference",
          child: false
        },*/
        {
          title: "Item Code",
          key: "barcode",
          child: false,
        },
        /*{
          title: "SKU",
          key: "sku",
          child: false
        },
        {
          title: "UPC",
          key: "upc",
          child: false
        },
        {
          title: "EAN",
          key: "ean",
          child: false
        },
        {
          title: "ISBN",
          key: "isbn",
          child: false
        },*/
        {
          title: "Part Number",
          key: "part_number",
          child: false,
        },
        /*  {
          title: "Serial Number",
          key: "serial_number",
          child: false,
        }, */
        /*{
          title: "HSN Code",
          key: "hsn_code",
          child: false
        },*/
        {
          title: "Description",
          key: "description",
          child: false,
        },
        /*{
          title: "Supplier",
          key: "supplier",
          child: "display_name",
        },*/
        {
          title: "Brand",
          key: "brand",
          child: "text",
        },
        /* {
          title: "Manufacturer",
          key: "manufacturer",
          child: "text",
        }, */
        {
          title: "Category",
          key: "category",
          child: "text",
        },
        /* {
          title: "UOM",
          key: "uom",
          child: "text",
        }, */
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.product = this.lodash.toSafeInteger(param.id);
        this.getSummary(false);
      },
    },
  },
  methods: {
    getSummary(scrollBottom) {
      const _this = this;
      if (_this.product > 0) {
        _this.dataLoading = true;
        _this.series = [];
        _this.$store
          .dispatch(QUERY, {
            url: "product/" + _this.product + "/summary",
            data: {
              filter: _this.summary,
            },
          })
          .then(({ data }) => {
            let categories = Object.values(data.categories);
            if (
              _this.lodash.isEmpty(categories) === false &&
              _this.lodash.isArray(categories)
            ) {
              _this.chartOptions = {
                ..._this.chartOptions,
                ...{
                  xaxis: {
                    categories: categories.map((row) => {
                      if (
                        _this.summary == "this_year" ||
                        _this.summary == "previous_year"
                      ) {
                        return moment(row).format("MMMM, YYYY");
                      }
                      return moment(row).format("Do, MMMM, YYYY");
                    }),
                  },
                },
              };

              if (_this.lodash.isEmpty(data.series) === false) {
                if (
                  _this.lodash.isEmpty(data.series.quotation_counted) === false
                ) {
                  _this.series.push({
                    name: "Quotation",
                    data: Object.values(data.series.quotation_counted),
                  });
                }
                if (
                  _this.lodash.isEmpty(data.series.invoice_counted) === false
                ) {
                  _this.series.push({
                    name: "Invoice",
                    data: Object.values(data.series.invoice_counted),
                  });
                }
                if (_this.lodash.isEmpty(data.series.job_counted) === false) {
                  _this.series.push({
                    name: "Job",
                    data: Object.values(data.series.job_counted),
                  });
                }
              }
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
            if (_this.lodash.isEmpty(_this.series) === false && scrollBottom) {
              let objDiv = _this.$refs["sidebarScrollbar"].$el;
              _this.$nextTick(() => {
                objDiv.scrollTop = objDiv.scrollHeight;
              });
            }
          });
      }
    },
  },
  mounted() {
    this.getSummary();
  },
};
</script>
